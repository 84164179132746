import { FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl"
import React from "react"
import SectionTitle from "../components/SectionTitle"
import "./ChiSiamo.css"

const ChiSiamo = ({ intl }) => (
  <div className="ChiSiamo ">
    <div className="ChiSiamoTextContainer">
      <SectionTitle titleId="chisiamo-title:label" />
      <FormattedHTMLMessage id="chisiamo-description:label" />
    </div>
  </div>
)

export default injectIntl(ChiSiamo)
